<template>
  <ion-page id="main-content">

    <!-- Header - Auth -->
    <div v-if="isAuth === true && slug">
      <Header page="profile" :slug="slug" />
    </div>
    <!-- Header - Public -->
    <div v-else>
      <ion-header id="app-header" :translucent="true">
        <ion-toolbar class="text-center">
          <ion-buttons slot="start">
            <ion-button @click.prevent="updateRoute('/')">
              <ion-icon :icon="menu"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click.prevent="updateRoute('/sign-in')">
              <ion-icon :icon="ellipsisHorizontal"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title v-if="profile && profile != []">
            {{profile.slug}}
          </ion-title>
          <LogoMark v-else color="light" />

        </ion-toolbar>
      </ion-header> 
    </div>

    <!-- Page Content -->
    <ion-content :fullscreen="true">
      <div v-if="profile && profile != []">
        <!-- 
          Profile Image 
        -->
        <ion-grid>
          <ion-row>
            <ion-col size="3" class="text-center padding-sm">
              <ion-thumbnail class="margin-auto" @click="updateRoute('profile-photo')">
                <img :src="profileImage" class="img-avatar w-100" />
              </ion-thumbnail>
            </ion-col>
            <ion-col size="9" class="padding-top-md">
              <ion-grid class="m-0 p-0">
                <ion-row class="m-0 p-0">
                  <ion-col size="6" class="text-center m-0 p-0">
                    <div class="text-center" style="font-size:12px;">
                      Followers
                    </div>
                    <div v-if="isAuth === true" class="text-center pr-1">
                      <ion-button size="small" color="light" class="w-100">
                        Follow
                      </ion-button>
                    </div>
                  </ion-col>
                  <ion-col size="6" class="text-center m-0 p-0">
                    <div class="text-center" style="font-size:12px;">
                      Following
                    </div>
                    <div v-if="isAuth === true" class="text-center pl-1">
                      <ion-button size="small" color="light" class="w-100">
                        Message
                      </ion-button>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- 
          Name & Location
        -->
        <div class="padding-left-md padding-right-md">
          <span class="text-capitalize text-lg bold">{{profile.name}}</span>
          <p v-if="profile.location != null && profile.location != ''" class="m-0 p-0 text-sm">
            {{profile.location}}
          </p>
        </div>
        <!-- 
          Bio
        -->
        <div v-if="profile.bio != null && profile.bio != ''" class="padding-left-md padding-right-md padding-top-sm text-md">
          {{profile.bio}}
        </div>
        <!-- 
          URL 
        -->
        <div v-if="profile.url != null && profile.url != ''" class="padding-left-md padding-right-md padding-top-sm">
          <ion-text class="pointer" @click.prevent="goToLink()">
            {{profile.url}}
          </ion-text>
        </div>
        <!-- 
          Genres 
        -->
        <div v-if="profile.genres" class="padding-sm">
          <ion-chip v-for="genre in profile.genres" :key="genre">
            <span style="font-size:12px;" class="text-capitalize">{{genre.name}}</span>
          </ion-chip>
        </div>
      </div>
      <!-- Profile Not Found -->
      <div v-else class="ion-padding">
        We can't find that profile.
      </div>
    </ion-content>

    <!--Footer -->
    <ion-footer v-if="device != 'web' && isAuth === true">
      <Tabs page="profile" />
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, loadingController, IonGrid, IonRow, IonCol, IonThumbnail, IonTitle, IonChip, IonText } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { search, options, menu, mail, arrowBack, chevronBack, ellipsisVertical, ellipsisHorizontal,settings, home, logIn, person  } from 'ionicons/icons';
import LogoMark from '../components/LogoMark.vue';
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import Header from '../components/Header.vue';
import { useRouter } from 'vue-router';
import { isPlatform } from '@ionic/vue';
//import { useStore } from "vuex";
import apiClient from '../services/api';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

export default defineComponent({
  name: 'Artists',
  components: {
    IonPage, IonContent, IonFooter, Tabs, Header, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, LogoMark, IonGrid, IonRow, IonCol, IonThumbnail, IonTitle, IonChip, IonText
  }, 
  setup() {
    const router = useRouter();
    //const store = useStore();
    const isAuth = ref(false);
    const view = ref('feed');
    const device = ref('web');
    const slug = ref(null);
    const profile = ref([]);
    const timeout = { default: 6000 }
    const profileImage = ref('https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg');

    onMounted(() => {
      isAuth.value = doAuth('profile');

      slug.value = router.currentRoute._rawValue.params.slug
      
      if(slug.value != null && slug.value != undefined && slug.value != '') {
        getProfile()
      } 
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
    })

    function getProfile() {
      presentLoading()
      apiClient.get('/api/get/profile-by-slug/' + slug.value)
      .then(response => {
        profile.value = response.data
        if(profile.value.image) {
          profileImage.value = profile.value.image
        }
      }).catch(error => {
        console.log(error)
      });
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function goToLink() {
      await Browser.open({ url: profile.value.url });
    }

    function updateRoute(r) {
      router.push(r)
    }

    return {
      updateRoute, router, view, device, search, options, menu, mail, arrowBack, chevronBack, ellipsisVertical, settings, home, logIn, person, profile, slug, isAuth, ellipsisHorizontal, profileImage, goToLink
    }
  }

});
</script>

<style scoped>

ion-thumbnail {
  --size:72px;
}



</style>
